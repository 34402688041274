import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LastUpdated from "../components/LastUpdated/LastUpdated"
import Icon from "../components/Utilities/Icon"

// import '../css/index.css'; // add some style if you want!

export default function Index({ data }) {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <Layout>
      <SEO
        title={"Articles on Lionel Messi and Cristiano Ronaldo"}
        canonicalPath={`/articles/`}
        description={`Explore Messi and Ronaldo's entire careers - every match, goal, assist, free kick, opponent, competition... everything!`}
      />

      <h1>Articles</h1>

      <div className="w-full mx-auto max-w-screen-3xl">

        <div className="blog-posts flex flex-wrap mt-6 2xl:mt-10">
          {posts
            .filter(post => post.node.frontmatter.title.length > 0)
            .map(({ node: post }) => {
              return (
                <div className={`post-card flex p-2 lg:p-4 w-full sm:w-1/2 md:w-1/3 2xl:w-1/4 relative ${post.frontmatter.pinned ? 'order-first' : ''}`}>

                  <Link to={`/articles/${post.frontmatter.slug}/`} className={`flex flex-col items-start p-6 2xl:p-8 bg-noir-lighten5 rounded-md border transition duration-300 transform hover:scale-105 ${post.frontmatter.pinned ? 'border-highlight' : 'border-white border-opacity-10 hover:border-opacity-70'}`} key={post.id}>

                    <h2 className="text-xl xl:text-2xl leading-snug font-semibold">
                      {post.frontmatter.title}
                    </h2>

                    <p className="uppercase text-xs tracking-wider opacity-50 my-3 xl:my-4">{post.frontmatter.date}</p>

                    <p className="text-sm xl:text-base leading-relaxed opacity-70 mb-5 xl:mb-8">{post.excerpt}</p>

                    <button className="text-sm xl:text-base mt-auto px-4 py-3 font-medium leading-snug border border-highlight text-highlight block rounded-md transition duration-500">Read More</button>
                    
                    {post.frontmatter.pinned ? (
                      <p className="absolute top-0 right-0 flex items-center text-xs p-1 bg-highlight text-noir rounded-tr-md rounded-bl-md">
                        <Icon iconName='icon-pin' class="text-noir mr-1" />
                        <span>Pinned</span>
                      </p>
                    ) : ''}

                  </Link>

                </div>
              )
            })}
        </div>

      </div>

      <LastUpdated type="Stats" />
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 100)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            slug
            pinned
          }
        }
      }
    }
  }
`
